import * as React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import Card from './base'
import { Title } from "../atoms/titles"
import Play from "../../images/play-triangle.svg"

const VideoCard = ({
  title,
  description,
  image = {
    url: '',
    alt: '',
  },
  cta = {
    label: '',
    url: '',
  },
  className,
  style = {},
  children,
  onClick,
  ...props
}) => {

  //console.log(image)

  return (
    <Card 
    className={'relative ' + className} 
    //style={{ backgroundImage: `url("${image.url}")` }}
    >
      <div className=" mx-auto">
        <img src={image.url} alt={image.alt} className="mx-auto" />
      </div>

      <div className="absolute left-0 right-0 top-0 m-0 h-full z-9 ">
        <div className="h-2/3 lg:h-full w-full  flex flex-col justify-center ">
          <button className="w-24 h-24 lg:w-44 lg:h-44  border border-2 rounded-full text-white mx-auto font-bold" onClick={onClick}>
            <img src={Play} alt="play" className="mx-auto" />
          </button>
        </div>
        
      </div>

      <div className="px-16 " >
          <Title className="text-xl py-2 text-dark text-center leading-6">{title}</Title>
          <div>
            {cta && <Link to={cta.url} className="button-primary">{cta.label}</Link>}
          </div>
        </div>
    </Card>
  )
}


VideoCard.defaultProps = {
  title: PropTypes.string,//  "Product name",
  description: PropTypes.string,//  "Product name",
  className: "border py-9 px-8",
  image: {
    url: PropTypes.string, //'https://picsum.photos/484/272',
    alt: 'some thing'
  },
  cta: {
    url: PropTypes.string,
    label: 'Read more'
  },
};

const VideoCardExample = () => {

  return <VideoCard
    className='border py-1 px-1 h-[500px]'
    title="כותרת זיפור"
    description="בב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התיד"
    image={{
      url: 'https://picsum.photos/917/516?random=' + Math.round(Math.random() * 1000),
      alt: 'some thing'
    }}
    cta={{
      url: '/',
      label: 'קרא עוד'
    }}
  />
}

export default VideoCard
export { VideoCardExample }
