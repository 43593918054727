import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import VideoCard from "../components/cards/video"
import SectionGrid3 from "../components/sections/grid/grid3"
import HeroInner2 from "../components/sections/hero/inner2"
import YouTube from 'react-youtube';
//import Agent from "../translations/agent"

const IndexPage = ({ data: { node } }) => {

  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={node.relationships.section1} />
    </Layout>
  )
}


const Hero = ({ data }) => {

  const { title, subtitle } = data
  //const url = data?.relationships?.image?.localFile?.publicURL
  const action1 = data.relationships.actions[0]

  const backgrounds = data?.relationships?.images.map((item,index) => (
    item.localFile.publicURL
  ))

  return (
    <HeroInner2
      title={title}
      description={subtitle}
      action1={action1}
      backgrounds={backgrounds}
    />
  )
}

const Section1 = ({ data }) => {

  const [isPlayerOpen, setIsPlayerOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState({})

  const onClick = (card) => {
    //console.log('clicked')
    setSelectedCard(card)
    setIsPlayerOpen(true)
  }

  const closeModal = () => {
    //console.log('closeModal')
    setSelectedCard({})
    setIsPlayerOpen(false)
  }

  //console.log('section1', data)

  const cards = data
    .filter(card => card?.youtube?.video_id)
    .map((card, index) => {

      const props = {}

      props.onClick = () => onClick(card)

      if (card?.relationships?.image) {
        props.image = {
          url: card?.relationships?.image?.localFile?.publicURL,
          alt: card.image.alt
        }
      } else {
        props.image = {
          //url:  'https://picsum.photos/917/516?random=' + card.id,
          url: `https://img.youtube.com/vi/${card?.youtube?.video_id}/0.jpg`,
          alt: 'some thing'
        }
      }

      return (
        <VideoCard
          key={index}
          className='py-1 px-1  bg-no-repeat bg-cover bg-white'
          title={card.title}
          cta={false}
          {...props}
        />
      )
    })

  return (
    <>
      <VideoPlayer isOpen={isPlayerOpen} close={closeModal} card={selectedCard} />
      <SectionGrid3
        className="lg:mt-24 py-8 lg:px-10 px-4"
        classNameGrid="lg:gap-y-10 gap-x-12"
      >
        {cards}
      </SectionGrid3>
    </>
  )

}


const VideoPlayer = ({ isOpen, close, card }) => {

  //const url = card?.relationships?.video.localFile.publicURL
  const url = card?.youtube?.video_id
  //console.log(url)

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };


  return (

    <div
      className={"fixed z-10 inset-0 bg-gray-600  bg-opacity-80 --overflow-y-auto h-full w-full  " + (isOpen ? 'block' : 'hidden')}
      //id="my-modal"
      onClick={close}
      onKeyDown={close}
      role="presentation"
    >

      <div
        className="relative top-20 inset-0 mx-auto  shadow-lg rounded-md bg-white w-2/3 h-1/2"
      >

        <YouTube
          videoId={url}
          opts={opts}
          className="p-1 h-full w-full "
          onEnd={close}
        //onReady={this._onReady}
        />

      </div>
    </div>

  )
}
export default IndexPage


export const query = graphql`
  query gallery($id: String!) {
    node: nodeGallery(id: {eq: $id }) {
      id
      title
      metatag {
        attributes {
        content
          name
        }
      tag
      }
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section1: field_paragraph_multi{
          ...GalleryItemContent
        }
      }
    }
  }
`
